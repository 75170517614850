import {ReactElement} from 'react';
import {MetaFunction} from 'remix';

import Home from '~/pages/Home';

export const meta: MetaFunction = () => {
    return {
        title: 'It universe',
        description: 'Welcome to It universe!',
    };
};

export default function Index(): ReactElement {
    return (
        <>
            {/* Hello */}
            <Home />
        </>
    );
}
