import {ReactElement} from 'react';
import {FormattedDate, FormattedMessage} from 'react-intl';

const blogPosts = [
    {
        id: 1,
        title: 'When is the cheapest time to book your hire car?',
        href: '#',
        datetime: '2020-03-01',
        category: {name: 'Article', href: '#'},
        imageUrl:
            'https://images.unsplash.com/photo-1603803669776-ae2317cf3c97?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80',
        preview:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto accusantium praesentium eius, ut atque fuga culpa, similique sequi cum eos quis dolorum.',
        author: {
            name: 'Julia Delamare',
            imageUrl:
                'https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
            href: '#',
        },
        readingLength: '6',
    },
    {
        id: 2,
        title: 'Understanding car hire charges, disputes and your rights',
        href: '#',
        datetime: '2020-03-10',
        category: {name: 'Article', href: '#'},
        imageUrl:
            'https://images.unsplash.com/photo-1626522139680-ed61b221528e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2064&q=80',
        preview:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit facilis asperiores porro quaerat doloribus, eveniet dolore. Adipisci tempora aut inventore optio animi., tempore temporibus quo laudantium.',
        author: {
            name: 'Julia Delamare',
            imageUrl:
                'https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
            href: '#',
        },
        readingLength: '4',
    },
    {
        id: 3,
        title: 'Get the most out of your rental whilst on holiday',
        href: '#',
        datetime: '2020-02-12',
        category: {name: 'Article', href: '#'},
        imageUrl:
            'https://images.unsplash.com/photo-1499062918700-389fa905494e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=928&q=80',
        preview:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint harum rerum voluptatem quo recusandae magni placeat saepe molestiae, sed excepturi cumque corporis perferendis hic.',
        author: {
            name: 'Julia Delamare',
            imageUrl:
                'https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
            href: '#',
        },
        readingLength: '11',
    },
];

export default function BlogHighlights(): ReactElement {
    return (
        <div className="relative bg-gray-50 py-16 sm:py-24 lg:py-32">
            <div className="relative">
                <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
                    <h2 className="text-base font-semibold uppercase tracking-wider text-pink-600">
                        <FormattedMessage
                            id="learn.label"
                            defaultMessage="Learn"
                        />
                    </h2>
                    <p className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                        <FormattedMessage
                            id="learn.title"
                            defaultMessage="Helpful Car Hire Resources"
                        />
                    </p>
                    <p className="mx-auto mt-5 max-w-prose text-xl text-gray-500">
                        <FormattedMessage
                            id="learn.description"
                            defaultMessage="Whether it's your first time hiring a car, your
                            looking for the cheapest deals, or you're just
                            unsure about car insurance or hire disputes, then we are
                            here to help with car hire tips and travel advice"
                        />
                    </p>
                </div>
                <div className="mx-auto mt-12 grid max-w-md gap-8 px-4 sm:max-w-lg sm:px-6 lg:max-w-7xl lg:grid-cols-3 lg:px-8">
                    {blogPosts.map((post) => (
                        <div
                            key={post.id}
                            className="flex flex-col overflow-hidden rounded-lg shadow-lg"
                        >
                            <div className="flex-shrink-0">
                                <img
                                    className="h-48 w-full object-cover"
                                    src={post.imageUrl}
                                    alt=""
                                />
                            </div>
                            <div className="flex flex-1 flex-col justify-between bg-white p-6">
                                <div className="flex-1">
                                    <p className="text-sm font-medium text-pink-600">
                                        <a
                                            href={post.category.href}
                                            className="hover:underline"
                                        >
                                            <FormattedMessage
                                                id="learn.format"
                                                defaultMessage="Article"
                                            />
                                        </a>
                                    </p>
                                    <a href={post.href} className="mt-2 block">
                                        <p className="text-xl font-semibold text-gray-900">
                                            {post.title}
                                        </p>
                                        <p className="mt-3 text-base text-gray-500">
                                            {post.preview}
                                        </p>
                                    </a>
                                </div>
                                <div className="mt-6 flex items-center">
                                    <div className="flex-shrink-0">
                                        <a href={post.author.href}>
                                            <img
                                                className="h-10 w-10 rounded-full"
                                                src={post.author.imageUrl}
                                                alt={post.author.name}
                                            />
                                        </a>
                                    </div>
                                    <div className="ml-3">
                                        <p className="text-sm font-medium text-gray-900">
                                            <a
                                                href={post.author.href}
                                                className="hover:underline"
                                            >
                                                {post.author.name}
                                            </a>
                                        </p>
                                        <div className="flex space-x-1 text-sm text-gray-500">
                                            <time dateTime={post.datetime}>
                                                <FormattedDate
                                                    value={
                                                        new Date(post.datetime)
                                                    }
                                                    year="numeric"
                                                    month="long"
                                                    day="numeric"
                                                />
                                            </time>
                                            <span aria-hidden="true">
                                                &middot;
                                            </span>
                                            <span>
                                                <FormattedMessage
                                                    id="learn.length"
                                                    description="11 min read"
                                                    defaultMessage="{length} min read"
                                                    values={{
                                                        length: post.readingLength,
                                                    }}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
