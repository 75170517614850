import {
    AnnotationIcon,
    GlobeAltIcon,
    LightningBoltIcon,
    ScaleIcon,
} from '@heroicons/react/outline';
import {ReactElement} from 'react';
import {FormattedMessage} from 'react-intl';

export default function FeaturesSection(): ReactElement {
    return (
        <div className="bg-white py-16 sm:py-24 lg:py-32 lg:pt-24">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="lg:text-center">
                    <h2 className="text-base font-semibold uppercase tracking-wide text-pink-600">
                        <FormattedMessage
                            id="feature.label"
                            defaultMessage="Car Hire"
                        />
                    </h2>
                    <p className="mt-2 text-3xl font-extrabold leading-8 tracking-tight text-gray-900 sm:text-4xl">
                        <FormattedMessage
                            id="feature.title"
                            defaultMessage="Book with the car hire experts"
                        />
                    </p>
                    <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
                        <FormattedMessage
                            id="feature.description"
                            defaultMessage="Search and compare prices from leading car hire companies and save..."
                        />
                    </p>
                </div>

                <div className="mt-10">
                    <dl className="space-y-10 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10 md:space-y-0">
                        <div className="relative">
                            <dt>
                                <div className="absolute flex h-12 w-12 items-center justify-center rounded-md bg-pink-500 text-white">
                                    <GlobeAltIcon
                                        className="h-6 w-6"
                                        aria-hidden="true"
                                    />
                                </div>
                                <p className="ml-16 text-lg font-medium leading-6 text-gray-900">
                                    <FormattedMessage
                                        id="feature.item1.title"
                                        defaultMessage="Compare car hire deals instantly and effortlessly."
                                    />
                                </p>
                            </dt>
                            <dd className="mt-2 ml-16 text-base text-gray-500">
                                <FormattedMessage
                                    id="feature.item1.description"
                                    defaultMessage="By joining award-winning technology with our great user experience, you can effortlessly compare dozens of car hire providers from thousands of rental locations across the globe."
                                />
                            </dd>
                        </div>
                        <div className="relative">
                            <dt>
                                <div className="absolute flex h-12 w-12 items-center justify-center rounded-md bg-pink-500 text-white">
                                    <ScaleIcon
                                        className="h-6 w-6"
                                        aria-hidden="true"
                                    />
                                </div>
                                <p className="ml-16 text-lg font-medium leading-6 text-gray-900">
                                    <FormattedMessage
                                        id="feature.item2.title"
                                        defaultMessage="Have an issue with your hire car booking?"
                                    />
                                </p>
                            </dt>
                            <dd className="mt-2 ml-16 text-base text-gray-500">
                                <FormattedMessage
                                    id="feature.item2.description"
                                    defaultMessage="We are always here to support you through your rental.  You can contact us online, via email or call our customer services 24/7 directly with any booking questions, concerns or changes."
                                />
                            </dd>
                        </div>
                        <div className="relative">
                            <dt>
                                <div className="absolute flex h-12 w-12 items-center justify-center rounded-md bg-pink-500 text-white">
                                    <LightningBoltIcon
                                        className="h-6 w-6"
                                        aria-hidden="true"
                                    />
                                </div>
                                <p className="ml-16 text-lg font-medium leading-6 text-gray-900">
                                    <FormattedMessage
                                        id="feature.item3.title"
                                        defaultMessage="Book with confidence with free booking cancellation"
                                    />
                                </p>
                            </dt>
                            <dd className="mt-2 ml-16 text-base text-gray-500">
                                <FormattedMessage
                                    id="feature.item3.description"
                                    defaultMessage="Plans change, and travelling can be unpredictable, especially with the added uncertainty Coronavirus brings.  That's why you can book your car hire with us knowing that if your plans change, so can your booking with no additional costs."
                                />
                            </dd>
                        </div>
                        <div className="relative">
                            <dt>
                                <div className="absolute flex h-12 w-12 items-center justify-center rounded-md bg-pink-500 text-white">
                                    <AnnotationIcon
                                        className="h-6 w-6"
                                        aria-hidden="true"
                                    />
                                </div>
                                <p className="ml-16 text-lg font-medium leading-6 text-gray-900">
                                    <FormattedMessage
                                        id="feature.item4.title"
                                        defaultMessage="Work with the car hire experts"
                                    />
                                </p>
                            </dt>
                            <dd className="mt-2 ml-16 text-base text-gray-500">
                                <FormattedMessage
                                    id="feature.item4.description"
                                    defaultMessage="Our team and partners are the leading experts within the car rental market. Book from recognised brands such as Hertz, Europcar, and more or if you need advice on which car to hire, check out our blog or contact one of our team members."
                                />
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>
        </div>
    );
}
