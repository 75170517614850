import {CakeIcon, SearchIcon} from '@heroicons/react/outline';
import add from 'date-fns/add';
import {ReactElement, useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import classNames from '~/helpers/classNames';

import TimeSelect from '../TimeSelect';
import Toggle from '../Toggle';

import DatePicker from './components/date';
import LocationSearch from './components/location';

export default function SearchForm(): ReactElement {
    const [returnSameLocation, setReturnSameLocation] = useState(true);
    const [driverAge, setDriverAge] = useState(true);
    const [startDate, setStartDate] = useState<Date>(
        add(new Date(), {days: 1}),
    );
    const [endDate, setEndDate] = useState<Date>(add(new Date(), {days: 2}));

    useEffect(() => {
        if (startDate && endDate && startDate > endDate)
            setEndDate(add(startDate, {days: 1}));
    }, [startDate, endDate]);

    const intl = useIntl();

    const pickupPlaceholder = intl.formatMessage({
        id: 'search.pickup.placeholder',
        defaultMessage: 'City, Airport, address, station, postcode',
    });

    const pickUpDatePlaceholder = intl.formatMessage({
        id: 'search.pickupDate.placeholder',
        defaultMessage: 'Start date',
    });

    const returnDatePlaceholder = intl.formatMessage({
        id: 'search.returnDate.placeholder',
        defaultMessage: 'Return date',
    });

    const returnTimeLabel = intl.formatMessage({
        id: 'search.returnTime.label',
        defaultMessage: 'Return time',
    });

    const pickUpTimeLabel = intl.formatMessage({
        id: 'search.pickupTime.label',
        defaultMessage: 'Pick up time',
    });

    const returnLocationLabel = intl.formatMessage({
        id: 'search.return.question',
        defaultMessage: 'Return to same location',
    });

    const driverAgeLabel = intl.formatMessage({
        id: 'search.driversAge.question',
        defaultMessage: 'Driver aged between 25-70',
    });

    return (
        <div className="relative mx-auto mt-6 px-4 sm:px-6 md:mt-10 lg:px-8">
            <div className="rounded bg-pink-700 p-6 ">
                <form autoComplete="off">
                    <div
                        className={classNames(
                            returnSameLocation
                                ? 'lg:space-y-0'
                                : 'md:space-y-0',
                            'mb-4 flex flex-col flex-wrap space-y-4 md:flex-row',
                        )}
                    >
                        {/* Location Lookup */}
                        <div
                            className={classNames(
                                returnSameLocation
                                    ? 'lg:basis-0'
                                    : 'md:basis-0',
                                'grow sm:basis-full',
                            )}
                        >
                            <label
                                htmlFor="pickup"
                                className="block text-sm font-medium text-white"
                            >
                                <FormattedMessage
                                    id="search.pickup.label"
                                    defaultMessage="Pick-up"
                                />
                            </label>
                            <LocationSearch
                                placeholder={pickupPlaceholder}
                                name="pickup"
                                id="pickup"
                            />
                        </div>
                        {/* Pickup Date & Time */}
                        <div
                            className={classNames(
                                returnSameLocation
                                    ? ' basis-1/2  lg:ml-3 lg:max-w-t'
                                    : 'md:ml-3 md:max-w-t',
                                'flex',
                            )}
                        >
                            <div className="grow">
                                <label
                                    htmlFor="pickup-date"
                                    className="block text-sm font-medium text-white"
                                >
                                    <FormattedMessage
                                        id="search.pickupDate.label"
                                        defaultMessage="Pick-up date"
                                    />
                                </label>

                                <DatePicker
                                    placeholder={pickUpDatePlaceholder}
                                    name="pickup-date"
                                    id="pickup-date"
                                    startDate={startDate}
                                    onChange={setStartDate}
                                    endDate={endDate}
                                    isEnd={false}
                                />
                            </div>
                            <div className="ml-3">
                                <TimeSelect label={pickUpTimeLabel} />
                            </div>
                        </div>
                        {/* Return Date and Time */}
                        {returnSameLocation && (
                            <div className="flex basis-1/2 md:pl-3  lg:max-w-t">
                                <div className="grow">
                                    <label
                                        htmlFor="return-date"
                                        className="block text-sm font-medium text-white"
                                    >
                                        <FormattedMessage
                                            id="search.returnDate.label"
                                            defaultMessage="Return date"
                                        />
                                    </label>
                                    <DatePicker
                                        placeholder={returnDatePlaceholder}
                                        name="return-date"
                                        id="return-date"
                                        startDate={startDate}
                                        onChange={setEndDate}
                                        endDate={endDate}
                                        isEnd={true}
                                    />
                                </div>
                                <div className="ml-3">
                                    <TimeSelect label={returnTimeLabel} />
                                </div>
                            </div>
                        )}
                        {/* Drivers Age */}
                        {!driverAge && (
                            <div
                                className={classNames(
                                    returnSameLocation ? 'lg:ml-3' : 'md:ml-3',
                                    'search-form-age-width flex',
                                )}
                            >
                                <div>
                                    <label
                                        htmlFor="age"
                                        className="block text-sm font-medium text-white"
                                    >
                                        <FormattedMessage
                                            id="search.driversAge.label"
                                            defaultMessage="Drivers Age"
                                        />
                                    </label>
                                    <div className="relative mt-1 rounded-md shadow-sm">
                                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                            <CakeIcon
                                                className="h-5 w-5 text-pink-700"
                                                aria-hidden="true"
                                            />
                                        </div>
                                        <input
                                            type="text"
                                            name="age"
                                            id="age"
                                            autoComplete="off"
                                            data-lpignore="true"
                                            data-form-type="other"
                                            className="block w-full rounded-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            placeholder="Age"
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    {/* Return location and date */}
                    {!returnSameLocation && (
                        <div className="mb-6 flex flex-col  space-y-4 md:flex-row md:space-y-0">
                            <div className="grow ">
                                <label
                                    htmlFor="return"
                                    className="block text-sm font-medium text-white"
                                >
                                    <FormattedMessage
                                        id="search.return"
                                        defaultMessage="Return"
                                    />
                                </label>
                                <LocationSearch
                                    name="return"
                                    id="return"
                                    placeholder={pickupPlaceholder}
                                />
                            </div>

                            <div className="flex md:ml-3 md:max-w-t">
                                <div className="grow">
                                    <label
                                        htmlFor="return-date"
                                        className="block text-sm font-medium text-white"
                                    >
                                        <FormattedMessage
                                            id="search.returnDate"
                                            defaultMessage="Return date"
                                        />
                                    </label>
                                    <DatePicker
                                        placeholder={returnDatePlaceholder}
                                        name="return-date"
                                        id="return-date-different-location"
                                        startDate={startDate}
                                        onChange={setEndDate}
                                        endDate={endDate}
                                        isEnd={true}
                                    />
                                </div>
                                <div className="ml-3">
                                    <TimeSelect label={returnTimeLabel} />
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="flex flex-col space-y-5 md:flex-row md:space-y-0">
                        <div className="md:pt-2">
                            <Toggle
                                checked
                                onChange={setReturnSameLocation}
                                label={returnLocationLabel}
                            />
                        </div>
                        <div className="md:ml-3 md:pt-2">
                            <Toggle
                                checked
                                onChange={setDriverAge}
                                label={driverAgeLabel}
                            />
                        </div>
                        <div className="md:flex-2 flex grow justify-end md:flex-wrap">
                            <button
                                type="button"
                                className="btn-primary  w-full md:w-auto"
                            >
                                <SearchIcon
                                    className="-ml-1 mr-3 h-5 w-5"
                                    aria-hidden="true"
                                />
                                <FormattedMessage
                                    id="search.button"
                                    defaultMessage="Search"
                                />
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}
