/* This example requires Tailwind CSS v2.0+ */
import {Switch} from '@headlessui/react';
import {ReactElement, useState} from 'react';

import classNames from '~/helpers/classNames';

interface ToggleProps {
    checked: boolean;
    onChange: (value: boolean) => void;
    label: string;
}

export default function Toggle({
    checked,
    onChange,
    label,
}: ToggleProps): ReactElement {
    const [enabled, setEnabled] = useState(checked);

    const handleChange = (value: boolean): void => {
        setEnabled(value);
        if (onChange) onChange(value);
    };

    return (
        <Switch.Group as="div" className="flex items-center">
            <Switch
                checked={enabled}
                onChange={handleChange}
                className={classNames(
                    enabled ? 'bg-pink-400' : 'bg-gray-50',
                    'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-offset-2',
                )}
            >
                <span
                    aria-hidden="true"
                    className={classNames(
                        enabled ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                    )}
                />
            </Switch>
            <Switch.Label as="span" className="ml-3">
                <span className="text-sm font-medium text-white">{label}</span>
            </Switch.Label>
        </Switch.Group>
    );
}
