import {ReactElement} from 'react';
import {FormattedMessage} from 'react-intl';

export default function Stats(): ReactElement {
    return (
        <div className="bg-gray-100 pt-12 sm:pt-16">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="mx-auto max-w-4xl text-center">
                    <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
                        <FormattedMessage
                            id="stats.title"
                            defaultMessage="Trusted by travellers across the globe"
                        />
                    </h2>
                    <p className="mt-3 text-xl text-gray-500 sm:mt-4">
                        <FormattedMessage
                            id="stats.intro"
                            defaultMessage="Book with confidence, find the cheapest deals and best
                            customer support in one place"
                        />
                    </p>
                </div>
            </div>
            <div className="mt-10  bg-white">
                <div className="relative">
                    <div className="absolute inset-0 h-1/2 bg-gray-100" />
                    <div className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                        <div className="mx-auto max-w-4xl">
                            <dl className="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-3">
                                <div className="flex flex-col border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r">
                                    <dt className="order-2 mt-2 text-lg font-medium leading-6 text-gray-500">
                                        <FormattedMessage
                                            id="stats.info1.title"
                                            defaultMessage="Customer service"
                                        />
                                    </dt>
                                    <dd className="order-1 text-5xl font-extrabold text-pink-600">
                                        <FormattedMessage
                                            id="stats.info1.fact"
                                            defaultMessage="24/7"
                                        />
                                    </dd>
                                </div>
                                <div className="flex flex-col border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r">
                                    <dt className="order-2 mt-2 text-lg font-medium leading-6 text-gray-500">
                                        <FormattedMessage
                                            id="stats.info2.title"
                                            defaultMessage="Cancellation"
                                        />
                                    </dt>
                                    <dd className="order-1 text-5xl font-extrabold text-pink-600">
                                        <FormattedMessage
                                            id="stats.info2.fact"
                                            defaultMessage="Free"
                                        />
                                    </dd>
                                </div>
                                <div className="flex flex-col border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l">
                                    <dt className="order-2 mt-2 text-lg font-medium leading-6 text-gray-500">
                                        <FormattedMessage
                                            id="stats.info3.title"
                                            defaultMessage="Credit Card Fees"
                                        />
                                    </dt>
                                    <dd className="order-1 text-5xl font-extrabold text-pink-600">
                                        <FormattedMessage
                                            id="stats.info3.fact"
                                            defaultMessage="Zero"
                                        />
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
