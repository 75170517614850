import {Listbox, Transition} from '@headlessui/react';
import {ClockIcon} from '@heroicons/react/outline';
import {CheckIcon, SelectorIcon} from '@heroicons/react/solid';
import {Fragment, ReactElement, useState} from 'react';

import classNames from '~/helpers/classNames';

const time = [
    {id: '00:00', name: '00:00'},
    {id: '00:30', name: '00:30'},
    {id: '01:00', name: '01:00'},
    {id: '01:30', name: '01:30'},
    {id: '02:00', name: '02:00'},
    {id: '02:30', name: '02:30'},
    {id: '03:00', name: '03:00'},
    {id: '03:30', name: '03:30'},
    {id: '04:00', name: '04:00'},
    {id: '04:30', name: '04:30'},
    {id: '05:00', name: '05:00'},
    {id: '05:30', name: '05:30'},
    {id: '06:00', name: '06:00'},
    {id: '06:30', name: '06:30'},
    {id: '07:00', name: '07:00'},
    {id: '07:30', name: '07:30'},
    {id: '08:00', name: '08:00'},
    {id: '08:30', name: '08:30'},
    {id: '09:00', name: '09:00'},
    {id: '09:30', name: '09:30'},
    {id: '10:00', name: '10:00'},
    {id: '10:30', name: '10:30'},
    {id: '11:00', name: '11:00'},
    {id: '11:30', name: '11:30'},
    {id: '12:00', name: '12:00'},
    {id: '12:30', name: '12:30'},
    {id: '13:00', name: '13:00'},
    {id: '13:30', name: '13:30'},
    {id: '14:00', name: '14:00'},
    {id: '14:30', name: '14:30'},
    {id: '15:00', name: '15:00'},
    {id: '15:30', name: '15:30'},
    {id: '16:00', name: '16:00'},
    {id: '16:30', name: '16:30'},
    {id: '17:00', name: '17:00'},
    {id: '17:30', name: '17:30'},
    {id: '18:00', name: '18:00'},
    {id: '18:30', name: '18:30'},
    {id: '19:00', name: '19:00'},
    {id: '19:30', name: '19:30'},
    {id: '20:00', name: '20:00'},
    {id: '20:30', name: '20:30'},
    {id: '21:00', name: '21:00'},
    {id: '21:30', name: '21:30'},
    {id: '22:00', name: '22:00'},
    {id: '22:30', name: '22:30'},
    {id: '23:00', name: '23:00'},
    {id: '23:30', name: '23:30'},
];

interface TimeSelectProps {
    label: string;
}

export default function TimeSelect({label}: TimeSelectProps): ReactElement {
    const [selected, setSelected] = useState(time[21]);

    return (
        <div>
            <Listbox value={selected} onChange={setSelected}>
                {({open}) => (
                    <>
                        <Listbox.Label className="block text-sm font-medium text-white">
                            {label}
                        </Listbox.Label>
                        <div className="relative mt-1">
                            <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                    <ClockIcon
                                        className="h-5 w-5 text-pink-700"
                                        aria-hidden="true"
                                    />
                                </div>
                                <span className="block truncate pl-7 ">
                                    {selected.name}
                                </span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                    <SelectorIcon
                                        className="h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                    />
                                </span>
                            </Listbox.Button>

                            <Transition
                                show={open}
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    {time.map((person) => (
                                        <Listbox.Option
                                            key={person.id}
                                            className={({active}) =>
                                                classNames(
                                                    active
                                                        ? 'bg-indigo-600 text-white'
                                                        : 'text-gray-900',
                                                    'relative cursor-default select-none py-2 pl-8 pr-4',
                                                )
                                            }
                                            value={person}
                                        >
                                            {({selected, active}) => (
                                                <>
                                                    <span
                                                        className={classNames(
                                                            selected
                                                                ? 'font-semibold'
                                                                : 'font-normal',
                                                            'block truncate',
                                                        )}
                                                    >
                                                        {person.name}
                                                    </span>

                                                    {selected ? (
                                                        <span
                                                            className={classNames(
                                                                active
                                                                    ? 'text-white'
                                                                    : 'text-indigo-600',
                                                                'absolute inset-y-0 left-0 flex items-center pl-1.5',
                                                            )}
                                                        >
                                                            <CheckIcon
                                                                className="h-5 w-5"
                                                                aria-hidden="true"
                                                            />
                                                        </span>
                                                    ) : null}
                                                </>
                                            )}
                                        </Listbox.Option>
                                    ))}
                                </Listbox.Options>
                            </Transition>
                        </div>
                    </>
                )}
            </Listbox>
        </div>
    );
}
