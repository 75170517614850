import {ReactElement} from 'react';
import {FormattedMessage} from 'react-intl';

export default function FAQs(): ReactElement {
    return (
        <div className="bg-white">
            <div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
                <div className="lg:grid lg:grid-cols-3 lg:gap-8">
                    <div>
                        <h2 className="text-3xl font-extrabold text-gray-900">
                            <FormattedMessage
                                id="faq.title"
                                defaultMessage="Frequently asked questions"
                            />
                        </h2>
                        <p className="mt-4 text-lg text-gray-500">
                            <FormattedMessage
                                id="faq.description"
                                defaultMessage="Can't find the answer you're looking for? Reach out to our <link>customer support</link> team."
                                values={{
                                    link: (chunks: string) => (
                                        <a
                                            href="#"
                                            className="font-medium text-pink-600 hover:text-pink-500"
                                        >
                                            {chunks}
                                        </a>
                                    ),
                                }}
                            />
                        </p>
                    </div>
                    <div className="mt-12 lg:col-span-2 lg:mt-0">
                        <dl className="space-y-12">
                            <div>
                                <dt className="text-lg font-medium leading-6 text-gray-900">
                                    <FormattedMessage
                                        id="faq.question1.title"
                                        defaultMessage="Why should I trust ClickBookDrive with my car hire booking?"
                                    />
                                </dt>
                                <dd className="mt-2 text-base text-gray-500">
                                    <FormattedMessage
                                        id="faq.question1.answer"
                                        defaultMessage="We have access to the largest car hire inventory in the world. We compare car hire prices from all of the worlds most trusted international and local car hire companies such as Budget, Dollar, Enterprise and Thrifty among others to secure you the best deal."
                                    />
                                </dd>
                            </div>
                            <div>
                                <dt className="text-lg font-medium leading-6 text-gray-900">
                                    <FormattedMessage
                                        id="faq.question2.title"
                                        defaultMessage="What do I need to book a hire car?"
                                    />
                                </dt>
                                <dd className="mt-2 text-base text-gray-500">
                                    <FormattedMessage
                                        id="faq.question2.answer"
                                        defaultMessage="We have access to the largest car hire inventory in the world. We compare car hire prices from all of the worlds most trusted international and local car hire companies such as Budget, Dollar, Enterprise and Thrifty among others to secure you the best deal."
                                    />
                                </dd>
                            </div>
                            <div>
                                <dt className="text-lg font-medium leading-6 text-gray-900">
                                    <FormattedMessage
                                        id="faq.question3.title"
                                        defaultMessage="How do I find the cheapest car hire deal?"
                                    />
                                </dt>
                                <dd className="mt-2 text-base text-gray-500">
                                    <FormattedMessage
                                        id="faq.question3.answer"
                                        defaultMessage="We have access to the largest car hire inventory in the world. We compare car hire prices from all of the worlds most trusted international and local car hire companies such as Budget, Dollar, Enterprise and Thrifty among others to secure you the best deal."
                                    />
                                </dd>
                            </div>
                            <div>
                                <dt className="text-lg font-medium leading-6 text-gray-900">
                                    <FormattedMessage
                                        id="faq.question4.title"
                                        defaultMessage="What type of cars can I hire with ClickBookDrive?"
                                    />
                                </dt>
                                <dd className="mt-2 text-base text-gray-500">
                                    <FormattedMessage
                                        id="faq.question4.answer"
                                        defaultMessage="We have access to the largest car hire inventory in the world. We compare car hire prices from all of the worlds most trusted international and local car hire companies such as Budget, Dollar, Enterprise and Thrifty among others to secure you the best deal."
                                    />
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>
        </div>
    );
}
