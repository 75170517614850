import {LocationMarkerIcon, RefreshIcon} from '@heroicons/react/outline';
import {ReactElement, useState} from 'react';
import Autosuggest, {ChangeEvent} from 'react-autosuggest';
import {useQuery} from 'react-query';

import {
    LocationsAPIResponse,
    LocationDetail,
    LocationsAPISuggestionResponse,
} from '~/routes/api/locations';

function getSuggestionValue(suggestion: LocationDetail): string {
    return suggestion['@Name'];
}

function renderSuggestion(suggestion: LocationDetail): ReactElement {
    return <span>{suggestion['@Name']}</span>;
}

function renderSectionTitle(
    section: LocationsAPISuggestionResponse,
): ReactElement {
    return <strong>{section.title}</strong>;
}

function getSectionSuggestions(
    section: LocationsAPISuggestionResponse,
): LocationDetail[] {
    return section.suggestions;
}

function IconLoadingLocations(): ReactElement {
    return (
        <RefreshIcon
            className="h-5 w-5 animate-spin  text-pink-700"
            aria-hidden="true"
        />
    );
}

function IconLocations(): ReactElement {
    return (
        <LocationMarkerIcon
            className="h-5 w-5 text-pink-700"
            aria-hidden="true"
        />
    );
}

interface LocationSearchProps {
    placeholder: string;
    name: string;
    id: string;
}

async function getLocations(term: string): Promise<LocationsAPIResponse> {
    console.log('we are going to fetch', term);
    const res = await fetch(`/api/locations?location=${term}`);
    const data = await res.json<LocationsAPIResponse>();
    return data;
}

export default function LocationSearch({
    placeholder,
    name,
    id,
}: LocationSearchProps): ReactElement {
    const [props, setProps] = useState({
        placeholder,
        autoComplete: 'off',
        'data-lpignore': 'true',
        'data-form-type': 'other',
        value: '',
        name,
        id,
    });

    const {isLoading, data} = useQuery<LocationsAPIResponse, Error>(
        ['character', props.value],
        async () => getLocations(props.value),
    );

    const onChange = (
        _event: React.FormEvent<HTMLElement>,
        {newValue, method}: ChangeEvent,
    ): void => {
        console.log(method);
        setProps({...props, value: newValue || ''});
    };

    const onSuggestionsClearRequested = (): void => {
        console.log('i do nothing');
    };

    const onSuggestionsFetchRequested = (): void => {
        console.log('i also do nothing');
    };

    const inputIcon = isLoading ? <IconLoadingLocations /> : <IconLocations />;

    return (
        <div className="relative mt-1 rounded-md shadow-sm">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                {inputIcon}
            </div>

            <Autosuggest
                multiSection={true}
                suggestions={data?.suggestions || []}
                focusInputOnSuggestionClick={false}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                renderSectionTitle={renderSectionTitle}
                getSectionSuggestions={getSectionSuggestions}
                inputProps={{...props, onChange}}
            />
        </div>
    );
}
