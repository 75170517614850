import {ReactElement} from 'react';
import {FormattedMessage} from 'react-intl';

import SearchForm from '~/components/SearchForm';

export default function Hero(): ReactElement {
    return (
        <div className="relative  bg-gray-100">
            <div className="mx-auto max-w-7xl">
                <div className="relative z-10 pb-8 sm:pb-16  lg:w-full lg:max-w-7xl">
                    <svg
                        className="absolute inset-y-0 right-2/4 hidden h-full w-48 translate-x-1/2 transform text-gray-100 lg:block"
                        fill="currentColor"
                        viewBox="0 0 100 100"
                        preserveAspectRatio="none"
                        aria-hidden="true"
                    >
                        <polygon points="50,0 100,0 50,100 0,100" />
                    </svg>
                    <div className=" lg:max-w-2xl ">
                        <main className="mx-auto max-w-7xl px-4 pt-6 sm:px-6 sm:pt-12 md:pt-16 lg:px-8 lg:pr-32 lg:pt-20 xl:pt-28">
                            <div className="sm:text-center lg:text-left">
                                <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl md:text-6xl">
                                    <span className="block xl:inline">
                                        Compare cheap{' '}
                                    </span>
                                    <span className="block text-pink-600 xl:inline">
                                        car hire deals
                                    </span>
                                </h1>
                                <p className="mt-3 text-base text-gray-500 sm:mx-auto sm:mt-5 sm:max-w-xl sm:text-lg md:mt-5 md:text-xl lg:mx-0">
                                    <FormattedMessage
                                        id="hero.long"
                                        defaultMessage="We take the stress out of booking car hire, trust our car hire expertise to find you the best deal"
                                    />
                                </p>
                            </div>
                        </main>
                    </div>
                    <SearchForm />
                </div>
            </div>
            <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
                <img
                    className="h-56 w-full object-cover sm:h-72 md:h-96 lg:h-full lg:w-full"
                    src="https://images.unsplash.com/photo-1484373030460-8de45ac8796d"
                    alt=""
                />
            </div>
        </div>
    );
}
