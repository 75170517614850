import {ReactElement} from 'react';
import {FormattedMessage} from 'react-intl';

import LogoAvis from './logos/avis.svg';
import LogoBudget from './logos/budget.svg';
import LogoHertz from './logos/hertz.svg';
import LogoNational from './logos/national.svg';
import LogoThrifty from './logos/thrifty.svg';

export default function PartnerCloud(): ReactElement {
    return (
        <div className="bg-gray-100">
            <div className="mx-auto max-w-7xl px-4 pt-16 sm:px-6 lg:px-8">
                <p className="text-center text-sm font-semibold uppercase tracking-wide text-gray-500">
                    <FormattedMessage
                        id="partnerCloud.intro"
                        defaultMessage="Compare deals on car hire from 300 leading national and local car hire companies including"
                    />
                </p>
                <div className="mt-16 flex flex-row justify-between">
                    <div className="">
                        <img
                            className="max-w-none"
                            src={LogoAvis}
                            alt="Avis Car Hire"
                            style={{height: '40px'}}
                        />
                    </div>
                    <div className="">
                        <img
                            className="max-w-none"
                            src={LogoBudget}
                            alt="Budget Car Hire"
                            style={{height: '52px'}}
                        />
                    </div>
                    <div className="">
                        <img
                            className=""
                            src={LogoHertz}
                            alt="Hertz Car Hire"
                            style={{height: '50px'}}
                        />
                    </div>
                    <div className="">
                        <img
                            className=""
                            src={LogoThrifty}
                            alt="Thrifty Car Hire"
                            style={{height: '50px'}}
                        />
                    </div>

                    <div className="">
                        <img
                            className=""
                            src={LogoNational}
                            alt="National Car Hire"
                            style={{height: '45px'}}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
