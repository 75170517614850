import {
    CalendarIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
} from '@heroicons/react/outline';
import {format} from 'date-fns';
import {forwardRef, ReactElement} from 'react';
import ReactDatePicker from 'react-datepicker';

interface DatePickerProps {
    placeholder: string;
    name: string;
    id: string;
    startDate: Date;
    endDate: Date;
    onChange: (date: Date) => void;
    isEnd: boolean;
}

export default function DatePicker({
    placeholder,
    name,
    id,
    startDate,
    endDate,
    onChange,
    isEnd = false,
}: DatePickerProps): ReactElement {
    const ButtonInput = forwardRef<
        HTMLButtonElement,
        {value: string; onClick: () => void}
    >(({value, onClick}, ref) => (
        <button
            onClick={onClick}
            ref={ref}
            placeholder={placeholder}
            id={id}
            name={name}
            type="button"
            className="inline-flex w-full justify-start rounded border border-gray-300 bg-white px-3 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-0"
        >
            <span className="ml-7">
                {format(new Date(value), 'dd MMM yyyy')}
            </span>
        </button>
    ));

    ButtonInput.displayName = 'CalendarButton';

    return (
        <div className="relative mt-1 rounded-md shadow-sm">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <CalendarIcon
                    className="h-5 w-5 text-pink-700"
                    aria-hidden="true"
                />
            </div>
            <ReactDatePicker
                onChange={(date) => {
                    if (date) onChange(date);
                }}
                startDate={startDate}
                endDate={endDate}
                selected={isEnd ? endDate : startDate}
                customInput={
                    <ButtonInput
                        value={''}
                        onClick={function (): void {
                            throw new Error('Function not implemented.');
                        }}
                    />
                }
                selectsStart={!isEnd}
                selectsEnd={isEnd}
                renderCustomHeader={({
                    date,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                }) => (
                    <div className="flex items-center justify-between px-2 py-2">
                        <span className="text-lg text-gray-700">
                            {format(date, 'MMMM yyyy')}
                        </span>

                        <div className="space-x-2">
                            <button
                                onClick={decreaseMonth}
                                disabled={prevMonthButtonDisabled}
                                type="button"
                                className={`
                                    ${
                                        prevMonthButtonDisabled &&
                                        'cursor-not-allowed opacity-50'
                                    }
                                    inline-flex rounded border border-gray-300 bg-white p-1 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-0
                                `}
                            >
                                <ChevronLeftIcon className="h-5 w-5 text-gray-600" />
                            </button>

                            <button
                                onClick={increaseMonth}
                                disabled={nextMonthButtonDisabled}
                                type="button"
                                className={`
                                    ${
                                        nextMonthButtonDisabled &&
                                        'cursor-not-allowed opacity-50'
                                    }
                                    inline-flex rounded border border-gray-300 bg-white p-1 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-0
                                `}
                            >
                                <ChevronRightIcon className="h-5 w-5 text-gray-600" />
                            </button>
                        </div>
                    </div>
                )}
            />
        </div>
    );
}
