import {ReactElement} from 'react';

import BlogHighlights from '~/components/BlogHighlights';
import FAQs from '~/components/FAQs';
import FeaturesSection from '~/components/FeaturesSection';
import Footer from '~/components/Footer';
import Hero from '~/components/Hero';
import MainMenu from '~/components/MainMenu';
import PartnerCloud from '~/components/PartnerCloud';
import Stats from '~/components/Stats';

export default function Home(): ReactElement {
    return (
        <>
            <MainMenu />
            <Hero />
            <PartnerCloud />
            <Stats />
            <FeaturesSection />
            <BlogHighlights />
            <FAQs />
            <Footer />
        </>
    );
}
